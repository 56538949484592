import { createI18n } from "vue-i18n/index";

const messages = {
  en: {

    latipay: {
      buttons: {
        ok: "Ok",
        cancel: "Cancel",
        yes: "Yes",
        no: "No",
        back: "Go back",
      },
      home: {
        title: "Home",
      },
      loader: {
        loading: "Loading...",
      },
      alerts: {
        reason: "Reason: ",
      },
      aside: {
        categories: {
          dashboard: {
            title: "Dashboard",
          },
          order: {
            title: "Order",
            items: {
              orders: "Orders",
              statistics: "Statistics",
            },
          },
          product: {
            title: "Product",
            items: {
              products: "Products",
              productCategories: "Product Categories",
            },
          },
          management: {
            title: "Management",
            items: {
              administrators: "Administrators",
              branches: "Branches",
            },
          },
        },
      },
      userMenu: {
        language: "Language",
        account: "Account",
        signOut: "Sign Out",
      },
      widgets: {
        recentOrders: {
          title: "Recent Orders",
          orders: "{count} Orders",
          totalOrders: "Total Orders",
          orderDescription: "{name} has placed an order.",
          pickUp: "Pick Up",
          delivery: "Delivery",
          paymentMethodCash: "Cash",
          paymentMethodEftpos: "Efptos",
          paymentMethodOnline: "Payment Express",
        },
        monthlyOrdersSum: {
          title: "Monthly Sales",
        },
        monthlyOrdersCount: {
          title: "Monthly Orders",
        },
      },
      pages: {
        signIn: {
          signInToAdmin: "Sign In to Sushi Eden Admin",
          email: "Email",
          password: "Password",
          continue: "Continue",
          pleaseWait: "Please wait...",
          emailRequired: "Email is a required field",
          emailNotValid: "Email must be a valid email",
          passwordRequired: "Password is a required field",
          passwordNotLongEnough: "Password must be at least 4 characters",
          loginInvalidGrant: "The login detail is incorrect.",
          loginError: "Failed to process login request.",
          tryAgain: "Try again!",
        },
        error404: {
          title: "Page Not Found",
          description: "Seems there's nothing here",
          back: "Return Home",
        },
        error500: {
          title: "System Error",
          description: "Something went wrong!\nPlease try again later.",
          back: "Return Home",
        },
        dashboard: {
          title: "Dashboard",
        },
        statistics: {
          title: "Statistics",
        },
        orders: {
          title: "Orders",
          selectTextBranchAll: "Select Branch",
          searchHintCustomer: "Search customer",
          selectTextTypeAll: "Select Type",
          selectTextTypePickUp: "Pick Up",
          selectTextTypeDelivery: "Delivery",
          selectTextStatusAll: "Select Status",
          selectTextStatusPending: "Pending",
          selectTextStatusProcessing: "Processing",
          selectTextStatusProcessed: "Processed",
          selectTextStatusReady: "Ready",
          selectTextStatusShipping: "Shipping",
          selectTextStatusCompleted: "Completed",
          selectTextStatusCancelled: "Cancelled",
          buttonActions: "Actions",
          buttonView: "View Order",
          tblHeaderOrderNumber: "Order Number",
          tblHeaderBranch: "Branch",
          tblHeaderPrice: "Price",
          tblHeaderName: "Name",
          tblHeaderContact: "Contact",
          tblHeaderTelephone: "Phone",
          tblHeaderEmail: "Email",
          tblHeaderType: "Type",
          tblHeaderStatus: "Status",
          tblHeaderPaymentMethod: "Payment Method",
          tblHeaderPaymentStatus: "Payment Status",
          tblHeaderCheckoutTime: "Pickup/Delivery Time",
          tblHeaderDate: "Date",
          tblHeaderActions: "Actions",
          badgeTextTypePickUp: "Pick Up",
          badgeTextTypeDelivery: "Delivery",
          badgeTextStatusPending: "Pending",
          badgeTextStatusProcessing: "Processing",
          badgeTextStatusProcessed: "Processed",
          badgeTextStatusReady: "Ready",
          badgeTextStatusShipping: "Shipping",
          badgeTextStatusCompleted: "Completed",
          badgeTextStatusCancelled: "Cancelled",
        },
        order: {
          title: "Order Details",
        },
        products: {
          title: "Products",
          searchHint: "Search product name",
          selectTextBranchAll: "Select Branch",
          selectTextCategoryAll: "Select Category",
          selectTextIsDeliveryAll: "Delivery/Pickup Only",
          selectTextIsDeliveryDelivery: "Delivery",
          selectTextIsDeliveryPickupOnly: "Pickup Only",
          selectTextIsEnabledAll: "Enabled/Disabled",
          selectTextIsEnabledEnabled: "Enabled",
          selectTextIsEnabledDisabled: "Disabled",
          buttonAddProduct: "Add Product",
          buttonActions: "Actions",
          buttonView: "View Product",
          tblHeaderName: "Name",
          tblHeaderCategory: "Category",
          tblHeaderPrice: "Price",
          tblHeaderBranch: "Branch",
          tblHeaderDelivery: "Delivery",
          tblHeaderEnabled: "Enabled",
          tblHeaderDate: "Date",
          tblHeaderActions: "Actions",
          badgeTextEnabled: "Enabled",
          badgeTextDisabled: "Disabled",
          badgeTextDelivery: "Delivery",
          badgeTextPickupOnly: "Pickup Only",
        },
        product: {
          title: "Product Details",
        },
        productCategories: {
          title: "Product Categories",
          searchHint: "Search product category name",
          buttonAddProductCategory: "Add Product Category",
          buttonActions: "Actions",
          buttonView: "View Product Category",
          tblHeaderName: "Name",
          tblHeaderDate: "Date",
          tblHeaderActions: "Actions",
        },
        productCategory: {
          title: "Product Category Details",
          productCategoryNotFound: "Cannot find administrator",
        },
        administrators: {
          title: "Administrators",
          searchHint: "Search name or email",
          selectTextIsEnabledAll: "Enabled/Disabled",
          selectTextIsEnabledEnabled: "Enabled",
          selectTextIsEnabledDisabled: "Disabled",
          buttonAddAdministrator: "Add Administrator",
          buttonActions: "Actions",
          buttonView: "View Administrator",
          tblHeaderName: "Name",
          tblHeaderEmail: "Email",
          tblHeaderEnabled: "Enabled",
          tblHeaderDate: "Date",
          tblHeaderActions: "Actions",
          badgeTextEnabled: "Enabled",
          badgeTextDisabled: "Disabled",
        },
        administrator: {
          title: "Administrator Details",
          administratorNotFound: "Cannot find administrator",
        },
        branches: {
          title: "Branches",
          searchHint: "Search branch name",
          selectTextIsOpenAll: "Open/Closed",
          selectTextIsOpenOpen: "Open",
          selectTextIsOpenClosed: "Closed",
          selectTextIsEnabledAll: "Enabled/Disabled",
          selectTextIsEnabledEnabled: "Enabled",
          selectTextIsEnabledDisabled: "Disabled",
          buttonAddBranch: "Add Branch",
          buttonActions: "Actions",
          buttonView: "View Branch",
          buttonOpen: "Open Branch",
          buttonClose: "Close Branch",
          buttonEnable: "Enable Branch",
          buttonDisable: "Disable Branch",
          tblHeaderName: "Name",
          tblHeaderPhone: "Phone",
          tblHeaderAddress: "Address",
          tblHeaderOpen: "Open",
          tblHeaderEnabled: "Enabled",
          tblHeaderDate: "Date",
          tblHeaderActions: "Actions",
          badgeTextOpen: "Open",
          badgeTextClosed: "Closed",
          badgeTextEnabled: "Enabled",
          badgeTextDisabled: "Disabled",
          modalTitleOpenBranch: "Open branch",
          modalBodyOpenBranch: "Are you sure you want to open branch {name}?",
          modalOkTextOpenBranch: "Open",
          modalNoTextOpenBranch: "Cancel",
          modalTitleCloseBranch: "Close branch",
          modalBodyCloseBranch: "Are you sure you want to close branch {name}?",
          modalOkTextCloseBranch: "Close",
          modalNoTextCloseBranch: "Cancel",
          modalTitleEnableBranch: "Enable branch",
          modalBodyEnableBranch: "Are you sure you want to enable branch {name}?",
          modalOkTextEnableBranch: "Enable",
          modalNoTextEnableBranch: "Cancel",
          modalTitleDisableBranch: "Disable branch",
          modalBodyDisableBranch:
            "Are you sure you want to disable branch {name}?",
          modalOkTextDisableBranch: "Disable",
          modalNoTextDisableBranch: "Cancel",
          toastTextBranchOpenedSuccess: "Branch {name} opened successfully",
          toastTextBranchOpenedFailure: "Failed to open Branch {name}",
          toastTextBranchClosedSuccess: "Branch {name} closed successfully",
          toastTextBranchClosedFailure: "Failed to close branch {name}",
          toastTextBranchEnabledSuccess: "Branch {name} enabled successfully",
          toastTextBranchEnabledFailure: "Failed to enable branch {name}",
          toastTextBranchDisabledSuccess: "Branch {name} disabled successfully",
          toastTextBranchDisabledFailure: "Failed to disable branch {name}",
        },
        branch: {
          title: "Branch Details",
          branchNotFound: "Cannot find branch",
        },
        account: {
          title: "Account",
          secProfileDetails: "Profile Details",
          secChangePassword: "Change Password",
          tabOverview: "Overview",
          tabSettings: "Settings",
          fieldUsername: "Username",
          fieldUsernameReadonly: "Username is not changeable",
          fieldEmail: "Email",
          fieldEmailReadonly: "Email is not changeable",
          fieldLanguage: "Language",
          fieldLanguageDescription:
            "Please select a preferred language, including date, time, and number formatting.",
          fieldLanguageRequired: "Language is a required field",
          fieldPassword: "Password",
          fieldPasswordDescription: "Password must be at least 8 characters",
          fieldCurrentPassword: "Current Password",
          fieldCurrentPasswordRequired: "Current Password is a required field",
          fieldNewPassword: "New Password",
          fieldNewPasswordRequired: "New Password is a required field",
          fieldNewPasswordNotLongEnough:
            "New Password must be at least 4 characters",
          fieldConfirmNewPassword: "Confirm New Password",
          fieldConfirmNewPasswordRequired:
            "Password Confirmation is a required field",
          fieldConfirmNewPasswordNotLongEnough:
            "Password Confirmation must be at least 4 characters",
          fieldConfirmNewPasswordNotMatch: "Passwords must match",
          btnEditProfile: "Edit Profile",
          btnDiscard: "Discard",
          btnSaveChanges: "Save Changes",
          btnPleaseWait: "Please wait...",
          btnUpdatePassword: "Update Password",
          btnCancel: "Cancel",
          msgPasswordChanged: "Password is successfully changed!",
        },
      },
    },



    channels: "Channels",
    merchants: "Merchants",
    transactions: "Transactions",
    merchantSettlement:"Merchant Settlement",
    walletAdjustment: "Wallet Adjustment",
    walletStatement:"Wallet Statement",
    pricingPlan: "Pricing Plan",
    accountSource: "accountSource",
    accounts:"Accounts",
    manualOrders:"Manual Orders",
    accountStatement: "Account Statement",
    apar: "AP/AR",
    users:"Users",
    journal: "Journal",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    externalFinance:"External Finance",
    internalFinance:"Internal Finance",
    settlementRecords:"Settlement Records",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
